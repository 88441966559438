import classnames from 'classnames';
import React from 'react';

import Icon from './Icon';
import { SVGComponent } from './types';

interface Props extends Pick<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'disabled'> {
   icon: SVGComponent;
   label: string;
   iconSize?: number;
   active?: boolean;
}

const StackedIconButton: React.FC<Props> = ({
   icon,
   iconSize = 20,
   label,
   onClick,
   disabled,
   active,
}) => {
   return (
      <button
         className={classnames(
            'relative flex flex-col items-center p-2 hover:bg-green-40 bg-inherit border-none cursor-pointer',
         )}
         onClick={onClick}
         disabled={disabled}
      >
         <Icon svg={icon} size={iconSize} />
         <span className="block text-white">{label}</span>
      </button>
   );
};

export default StackedIconButton;
