import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '../assets/Home.svg';
import { ReactComponent as PropertiesIcon } from '../assets/Properties.svg';
import { ReactComponent as RequestsIcon } from '../assets/Requests.svg';
import { ReactComponent as TenantsIcon } from '../assets/Tenants.svg';
import { ReactComponent as TransactionsIcon } from '../assets/Transactions.svg';
import { StackedIconButton } from '../components';
import {
   DASHBOARD_ROUTE_PATH,
   PROPERTIES_ROUTE_PATH,
   REQUESTS_ROUTE_PATH,
   TENANTS_ROUTE_PATH,
   TRANSACTIONS_ROUTE_PATH,
} from '../route-paths';

const BottomBar: React.FC = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const routeClick = (route: string) => {
      navigate(`${route}`);
   };

   return (
      <div className="fixed w-full bottom-0 left-0 z-10 ">
         <div className="bg-green-700 absolute inset-x-0 bottom-0 h-16">
            <div className="flex flex-col flex-shrink items-center gap-5">
               <div className="relative flex flex-col items-stretch justify-center pb-2 pt-1 px-3">
                  <div className="grid grid-flow-col gap-8 text-xs md:text-sm auto-cols-fr mr-4">
                     <StackedIconButton
                        icon={HomeIcon}
                        onClick={(e) => routeClick(DASHBOARD_ROUTE_PATH)}
                        label="Home"
                        active={location.pathname === DASHBOARD_ROUTE_PATH}
                     />
                     <StackedIconButton
                        icon={TenantsIcon}
                        onClick={(e) => routeClick(TENANTS_ROUTE_PATH)}
                        label="Tenants"
                        active={
                           location.pathname === TENANTS_ROUTE_PATH ||
                           location.pathname.includes('tenant')
                        }
                     />
                     <StackedIconButton
                        icon={PropertiesIcon}
                        onClick={(e) => routeClick(PROPERTIES_ROUTE_PATH)}
                        label="Property"
                        active={location.pathname === PROPERTIES_ROUTE_PATH}
                     />
                     {/*<StackedIconButton*/}
                     {/*   icon={RequestsIcon}*/}
                     {/*   onClick={(e) => routeClick(REQUESTS_ROUTE_PATH)}*/}
                     {/*   label="Notifications"*/}
                     {/*   active={location.pathname === REQUESTS_ROUTE_PATH}*/}
                     {/*/>*/}
                     <StackedIconButton
                        icon={TransactionsIcon}
                        onClick={(e) => routeClick(TRANSACTIONS_ROUTE_PATH)}
                        label="Transactions"
                        active={location.pathname === TRANSACTIONS_ROUTE_PATH}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BottomBar;
